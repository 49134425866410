* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.expanded-rows .rs-table-cell-content{
  background-color: #f8f8f8;
}

#root{
  height: 100vh;
}