
.table {
    width: 100%; 
    border-collapse: collapse; 
    font-family: Calibri, Arial, sans-serif;
    font-size: 11pt;
    text-align: left;
}

.table th {
    position: sticky;
    top: 0; 
    z-index: 2;
    background-color: #d9e2f3; 
    color: #000; 
    border: 1px solid #9eb6ce; 
    font-size: 12pt; 
    padding: 4px; 
    text-align: center;
}

.table td.heading {
    position: sticky;
    left: 0; 
    z-index: 1; 
    background-color: #d9e2f3; 
    border: 1px solid #9eb6ce; 
    font-weight: bold; 
    padding: 4px; 
    text-align: center;
}

.table td {
    border: 1px solid #d0d7e5; 
    padding: 4px; 
    background-color: white; 
    white-space: nowrap; 
}

.table tr:hover td {
    background-color: #f5faff; 
}


.table td b {
    font-weight: bold;
}

.table tbody tr:nth-child(even) {
    background-color: #f7f7f7; 
}

.sticky-left {
    position: sticky;
    left: 0;
    background-color: #d9e2f3;
    border: 1px solid #9eb6ce; 
    z-index: 1;
}
